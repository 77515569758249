.Home .lander {
  padding: 10px 0;
  text-align: center;
}

.LoaderButton {
    margin-right:10px;
}

.radioButtons {
    margin-left:10px;
}

.homebutton {
    color: #fff;
    margin-right: 20px;
    background: rgb(51, 204, 153);
}