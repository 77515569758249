.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.LoaderButton {
    margin-right:10px;
}

.radioButtons {
    margin-left:10px;
}

.bg-light {
    background-color: unset !important;
}

.navbar-light .navbar-brand {
    text-align: center;
    width: 100%;
    font-weight: bold;
    color:black !important;
}

.row {
    margin-bottom: 10px;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative !important;
    z-index: 2 !important;
    width: 100% !important;
    height: calc(2.25rem + 2px) !important;
    margin: 0 !important;
    opacity: 0 !important;
}

.logo {
  background-image: url(../images/logo-dark.png);
  width: 212px;
  height: 60px;
  background-repeat: no-repeat;
  margin: 0 auto;
  margin-bottom:20px;
}

.fright {
    float:right;
}

.btn-primary {
    color: #fff;
    background-color: rgb(51, 204, 153);
    border-color: rgb(51, 204, 153);
}

.homebutton {
    width:212px !important;
}

.logoContainer {
    width:212px !important;
}

.midtext {
    width:75%;
    text-align:center;
}
.containerDiv {
    text-align:center;
}

.uploadData {
    width: 50%;
    margin: 0 auto;
    text-align:center;
}

.uploadDataForm {
    width: 50%;
    margin: 0 auto;
}

.radioButton {
    cursor: pointer;
}