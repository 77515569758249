.container {
    max-width: 100% !important;
}


.logo {
  background-image: url(../images/logo-dark.png);
  width: 212px;
  height: 60px;
  background-repeat: no-repeat;
  margin: 0 auto;
  margin-bottom:20px;
}

.fright {
    float:right;
}

.btn-primary {
    color: #fff;
    background-color: rgb(51, 204, 153) !important;
    border-color: rgb(51, 204, 153) !important;
}

homebutton {
    color: #fff;
    margin-right: 20px;
    background: rgb(51, 204, 153);
}